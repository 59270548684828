import { calculate } from 'utils';

/**
 * 用頻道過濾運費規則
 * @param {array} data 資料包
 * @param {string} platform 頻道
 * @returns {object}
 * {
 *  international: [{
 *    bbnMainKey: "超商取貨",
 *    fees: [xxx],
 *    id: 4,
 *    key: "family-mart",
 *    name: "全家取貨"
 *  }],
 *  domestic: [{
 *    bbnMainKey: "空運",
 *    fees: [xxx],
 *    id: 2,
 *    key: "air-freight",
 *    name: "空運"
 *  }]
 * }
 */
export const feeRuleByPlatform = (data, platform) =>
  data.map(({ id, key, bbnMainKey, name, rules }) => {
    // 若後台沒設定頻道費用表就吃預設(因後端會回傳空的 channelKey，已在 marketSlice 給預設值 key 叫 all)
    const config = rules[platform] ? rules[platform] : rules.all;
    const tempData = {
      id,
      key,
      bbnMainKey,
      name,
      fees: config,
    };

    return tempData;
  });

/**
 * 用金額過濾費用與門檻
 * @param {array} data 資料包
 * @param {number} price 當前金額加總
 * @returns {object}
 */
export const feeRuleByPrice = (data, price = 0) => {
  const resultThreshold =
    data.fees.reduce((acc, curr) => {
      if (price >= curr.minAmount) {
        return curr;
      }

      return acc;
    }) ?? 0;
  const resultEvent = feeRuleValidateEventPeriod(resultThreshold);

  return {
    threshold: resultThreshold.minAmount ?? 0,
    fee: resultThreshold.fee ?? 0,
    ...resultEvent,
  };
};

/**
 * [國際運費] 最小運費對應免運門檻
 * @param {array} data 運費包
 * @returns {object} 原運費包結構
 * @description 附上各種情境
 * 情境一: 最小費用為 {n}
 *    0~499 -> $200
 *    500~998 -> $100
 *    999~ -> $50
 * 情境二: 最小費用為 0
 *    0~499 -> $200
 *    500~998 -> $100
 *    999~ -> $0
 * 情境三: 最小費用一樣時，取門檻值最小
 *    0~499 -> $200
 *    500~998 -> $0
 *    999~ -> $0
 */
export const feeRuleFeeToThreshold = (data) =>
  data.reduce((acc, curr) => {
    // 當前 fee 更小，直接回傳當前項，若 fee 相同則比較 minAmount
    if (
      curr.fee < acc.fee ||
      (curr.fee === acc.fee && curr.minAmount < acc.minAmount)
    ) {
      return curr;
    }

    return acc;
  });

/**
 * 判斷是否為活動期間
 * @param {object} data 運費包
 * @returns {object}
 *  - isPeriod: 是否為活動期間
 *  - eventFee: 活動期間費用
 * @description 若為活動期間會有 eventFee 欄位
 */
export const feeRuleValidateEventPeriod = (data) => {
  const isPeriod = Object.prototype.hasOwnProperty.call(data, 'eventFee');
  const eventFee = isPeriod ? data?.eventFee : null;

  return {
    isPeriod,
    eventFee,
  };
};

/**
 * [國際運費] 門檻值(含是否活動期間)
 * @param {array} data 運費包
 * @returns {object}
 *  - threshold: 門檻值
 *  - fee: 費用
 *  - isPeriod: 是否為活動期間
 *  - eventFee: 活動期間費用
 * @example 固定為空運因此外層寫死 international[0]
 * feeRuleInternationalThreshold(international[0])
 */
export const feeRuleInternationalThreshold = (data) => {
  const resultThreshold = feeRuleFeeToThreshold(data.fees);
  const resultEvent = feeRuleValidateEventPeriod(resultThreshold);

  return {
    threshold: resultThreshold.minAmount ?? 0,
    fee: resultThreshold.fee ?? 0,
    ...resultEvent,
  };
};

/**
 * [推廣期間] 過濾全家取貨名稱與門檻
 * @param {array} data 運費包
 * @returns {object}
 *  - name: 物流名稱
 *  - threshold: 門檻值
 *  - fee: 費用
 *  - isPeriod: 是否為活動期間
 *  - eventFee: 活動期間費用
 * @example
 * feeRuleFilterFamilyMart(domestic)
 */
export const feeRuleFilterFamilyMart = (data) => {
  const resultFamilyMart = data.find(({ key }) => key === 'family-mart');
  const resultThreshold = feeRuleFeeToThreshold(resultFamilyMart.fees);
  const resultEvent = feeRuleValidateEventPeriod(resultThreshold);

  return {
    name: resultFamilyMart.name ?? '',
    threshold: resultThreshold.minAmount ?? 0,
    fee: resultThreshold.fee ?? 0,
    ...resultEvent,
  };
};

/**
 * [國際運費] 購物車進度條
 * @param {object} feeRuleData 運費包
 * @param {object} totalData 小計資料包
 * @returns {object}
 *  - free: 免運級距
 *  - current: 當前級距
 *  - initial: 預設費用
 *  - spread: 距離免運的價差
 */
export const feeRuleCartProgressBar = (feeRuleData, totalData) => {
  const { fees } = feeRuleData;
  const free = feeRuleInternationalThreshold(feeRuleData);

  // 初始費用
  const initial = fees.reduce((max, item) => Math.max(max, item.fee), 0);

  // 金額加總過濾當前級距(費用與門檻)
  const resultCurrent = fees.reduce((acc, curr) => {
    if (
      totalData.priceSale >= curr.minAmount &&
      curr.minAmount > acc.minAmount
    ) {
      return curr;
    }

    return acc;
  });
  const resultEvent = feeRuleValidateEventPeriod(resultCurrent);
  const current = {
    threshold: resultCurrent.minAmount ?? 0,
    fee: resultCurrent.fee ?? 0,
    ...resultEvent,
  };

  // 距離免運還差多少
  const spread =
    totalData.priceSale > free.threshold
      ? 0
      : calculate(free.threshold - totalData.priceSale);

  return {
    free,
    current,
    initial,
    spread,
  };
};

/**
 * [國內運費] 金額加總過濾費用
 * @param {array} data 運費包
 * @param {number} price 當前加總金額
 * @returns {array}
 */
export const feeRuleDomestic = (data, price = 0) => {
  const result = data.reduce((acc, item) => {
    acc.push({
      ...item,
      fees: feeRuleByPrice(item, price),
    });

    return acc;
  }, []);

  return result;
};

/**
 * 過濾國內運費包的 key
 * @param {array} data 要過濾的資料包
 * @param {number} id 當前 id
 * @returns {object}
 */
export const feeRuleFilterDomesticById = (data, id) =>
  data.find((obj) => obj.id === id);
