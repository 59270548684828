import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Service, suspense } from 'utils';

// 列表
export const fetchStoreList = createAsyncThunk(
  'store/fetchStoreList',
  async (_, { rejectWithValue }) => {
    let resData = {};
    let retry = 0;

    do {
      resData = await Service.storeList();

      if (resData.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData.code === -5001);

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue(resData.errMsg);
    }

    return resData.data.list;
  }
);

// 新增: 後端機制為先打 create 後再打第二支查詢要 create 結果，當結果回傳 code: 1 才去打 list
export const createStore = createAsyncThunk(
  'store/createStore',
  async (_, { rejectWithValue }) => {
    let resData = {};
    let queryStatus = {};
    let retry = 0;

    resData = await Service.storeCreate();

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue(resData.errMsg);
    }

    do {
      queryStatus = await Service.storeCreateQuery(resData.data.queueIndex);

      if (queryStatus.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (queryStatus.code === -5001);

    // 其他無法預期的錯誤
    if (queryStatus.code < 0) {
      return rejectWithValue(queryStatus.errMsg);
    }

    return queryStatus;
  }
);

// 詳細資訊(編輯頁)
export const fetchStoreDetail = createAsyncThunk(
  'receiver/fetchStoreDetail',
  async (reqData, { rejectWithValue }) => {
    let resData = {};
    let retry = 0;

    do {
      resData = await Service.storeDetail(reqData);

      if (resData.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData.code === -5001);

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue({
        type: 'detail',
        message: resData.errMsg,
      });
    }

    return resData;
  }
);

// 編輯
export const updateStore = createAsyncThunk(
  'store/updateStore',
  async (reqData) => {
    const resData = await Service.storeUpdate(reqData);
    return resData;
  }
);

// 刪除
export const deleteStore = createAsyncThunk(
  'store/deleteStore',
  async (reqData) => {
    let resData = {};
    let retry = 0;

    do {
      resData = await Service.storeDelete(reqData);

      if (resData.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData.code === -5001);

    return resData;
  }
);

const initialState = {
  list: [],
  detail: {},
};

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    resetStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStoreList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.list = payload;
      })
      .addCase(fetchStoreList.rejected, (state, { payload }) => {
        state.loading = false;
        state.message = payload;
      })
      .addCase(fetchStoreDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStoreDetail.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.detail = payload.data;
      })
      .addCase(fetchStoreDetail.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorType = payload.type;
        state.message = payload.message;
      })
      .addCase(createStore.pending, (state) => {
        state.loading = true;
      })
      .addCase(createStore.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.message = payload;
      });
  },
});

export const { resetStore } = storeSlice.actions;
export default storeSlice.reducer;
