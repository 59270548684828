import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const Head = ({
  title = '比比昂日貨',
  description = '比比昂日貨是PChome旗下位於日本東京的子公司－比比昂株式會社與全家便利商店合作的新服務。我們希望將日本的各式質感好物帶給台灣人，讓大家都能輕鬆透過比比昂購買喜歡的商品。買日貨就找比比昂!',
  ogTitle = title,
  ogDescription = description,
  ogImage = `https://${process.env.REACT_APP_HOST_S3}/family-mart/images/og_main.png`,
}) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="og:title" content={ogTitle} />
    <meta name="og:description" content={ogDescription} />
    <meta name="og:image" content={ogImage} />
  </Helmet>
);

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogImage: PropTypes.string,
};

export default Head;
