import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  redirect,
} from 'react-router-dom';

import { store } from 'reduxs/store';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';

import GlobalStyle from 'styles/global';
import { getParams, lazyRetry } from 'utils';
import Frame from 'containers/frame';
import Protected from 'containers/protected';
import Empty from 'components/empty';
import Loading from 'components/loading';

const HomeFrame = lazy(() => lazyRetry(() => import('containers/homeFrame')));
const Product = lazy(() => lazyRetry(() => import('pages/product')));
const Search = lazy(() => lazyRetry(() => import('pages/search')));
const SearchResult = lazy(() => lazyRetry(() => import('pages/searchResult')));
const SearchResultRakuma = lazy(() =>
  lazyRetry(() => import('pages/searchResultRakuma'))
);
const Category = lazy(() => lazyRetry(() => import('pages/category')));
const CategoryResult = lazy(() =>
  lazyRetry(() => import('pages/categoryResult'))
);
const PromotionPage = lazy(() =>
  lazyRetry(() => import('pages/home/promotionPage'))
);

// 需要權限的頁面
const Cart = lazy(() => lazyRetry(() => import('pages/cart')));
const Pay = lazy(() => lazyRetry(() => import('pages/pay')));
const Receiver = lazy(() => lazyRetry(() => import('pages/receiver')));
const ReceiverAdd = lazy(() => lazyRetry(() => import('pages/receiver/add')));
const ReceiverDetail = lazy(() =>
  lazyRetry(() => import('pages/receiver/detail'))
);
const Store = lazy(() => lazyRetry(() => import('pages/store')));
const StoreDetail = lazy(() => lazyRetry(() => import('pages/store/detail')));
const Order = lazy(() => lazyRetry(() => import('pages/order')));
const OrderDetail = lazy(() => lazyRetry(() => import('pages/order/detail')));
const Member = lazy(() => lazyRetry(() => import('pages/member')));
const MemberUser = lazy(() => lazyRetry(() => import('pages/member/user')));
const QaList = lazy(() => lazyRetry(() => import('pages/qa')));
const QaDetail = lazy(() => lazyRetry(() => import('pages/qa/detail')));
const FavoriteFrame = lazy(() =>
  lazyRetry(() => import('pages/member/favoriteFrame'))
);
const Restock = lazy(() => lazyRetry(() => import('pages/member/restock')));

// 折價券
import BaseCoupon from 'containers/baseCoupon';
const Coupon = lazy(() => lazyRetry(() => import('pages/coupon')));
const CouponDetail = lazy(() => lazyRetry(() => import('pages/coupon/detail')));

// 元件與功能範例
const Demo = lazy(() => lazyRetry(() => import('pages/demo')));
const DemoIcons = lazy(() => lazyRetry(() => import('pages/demo/demoIcons')));
const DemoCarousel = lazy(() =>
  lazyRetry(() => import('pages/demo/demoCarousel'))
);
const DemoModal = lazy(() => lazyRetry(() => import('pages/demo/demoModal')));
const DemoDrawer = lazy(() => lazyRetry(() => import('pages/demo/demoDrawer')));

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Frame />}>
      <Route index element={<HomeFrame />} />
      <Route path="/product/:id" element={<Product />} />
      <Route path="/search" element={<Search />} />
      <Route path="/search/:keyword" element={<SearchResult />} />
      <Route path="/search-result" element={<SearchResultRakuma />} />
      <Route path="/category" element={<Category />} />
      <Route
        path="/category/:id"
        loader={({ request }) => {
          const params = getParams(request.url);
          const ids = params?.ids;
          // 不存在ids, 或ids存在但無值
          if (!ids?.length || !ids?.[0]) {
            return redirect('/category');
          }
          return true;
        }}
        element={<CategoryResult />}
      />
      <Route path="/promotion/:key" element={<PromotionPage />} />

      {/* FIXME: 需檢查若沒有這個 key 就找不到頁面 */}
      <Route element={<Protected />}>
        {/* 購物車與結帳 */}
        <Route path="/cart" element={<Cart />} />
        <Route path="/pay/:key" element={<Pay />} />

        {/* 會員相關 */}
        <Route path="/member" element={<Member />} />
        <Route path="/member/user" element={<MemberUser />} />
        <Route path="/member/receiver" element={<Receiver />} />
        <Route path="/member/receiver/add" element={<ReceiverAdd />} />
        <Route path="/member/receiver/:id" element={<ReceiverDetail />} />
        <Route path="/member/store" element={<Store />} />
        <Route path="/member/store/:id" element={<StoreDetail />} />
        <Route path="/member/favorite" element={<FavoriteFrame />} />
        <Route path="/member/restock" element={<Restock />} />

        {/* 折價券 */}
        <Route path="/member/coupon" element={<BaseCoupon />}>
          <Route index element={<Coupon />} />
          <Route path=":id" element={<CouponDetail />} />
        </Route>

        {/* 問答相關 */}
        <Route path="/member/qa" element={<QaList />} />
        <Route path="/member/qa-order/:id" element={<QaDetail />} />
        <Route path="/member/qa-product/:id" element={<QaDetail />} />

        {/* 訂單相關 */}
        <Route path="/member/order" element={<Order />} />
        <Route path="/member/order/:id" element={<OrderDetail />} />
      </Route>

      {
        // 範例
        (process.env.REACT_APP_ENV === 'dev' ||
          process.env.REACT_APP_ENV === 'stage') && (
          <>
            <Route path="demo" element={<Demo />} />
            <Route path="/demo/modal" element={<DemoModal />} />
            <Route path="/demo/drawer" element={<DemoDrawer />} />
            <Route path="/demo/carousel" element={<DemoCarousel />} />
            <Route path="/demo/icons" element={<DemoIcons />} />
          </>
        )
      }

      {/* 業務邏輯
        - 先顯示 loading 再強行跳轉頁面
        - events 透過 nginx 設定為同一個 domain 但不同網站 "/events/" 的路由
        - 環境對應除了 dev 指向 stage 外，stage 對應 stage，production 對應 production
       */}
      <Route
        path="/events/*"
        loader={({ params }) => {
          const path = params['*'];
          const redirectUrl = `https://${
            process.env.REACT_APP_ENV === 'production' ? '' : 'stage-'
          }fmmart${process.env.REACT_APP_DOMAIN}/events/${path}`;

          window.location.href = redirectUrl;
          return true;
        }}
        element={<Loading loading={true} />}
      />

      {/* 錯誤情境 */}
      <Route
        path="*"
        element={
          <Empty
            title="系統錯誤或頁面不存在"
            content={<span>建議檢查連結或重試</span>}
          />
        }
      />
    </Route>
  )
);

/**
 * NOTE:
 * 幾個 CRUD 的頁面是獨立 route，畫面上考量 header 返回鍵能正常導頁且若做 single page 沒有可以關閉畫面的機制
 * 會造成頁面重刷 state 就不見，因此有些資料需仰賴 localStorage 暫存(可搭配 redux createSelector 做一些資料整理)，但要記得清除
 * Ex.
 *  - pay: 結帳頁由前一頁購物車列表做選取帶到結帳頁(因結帳頁顯示沒有 API，後端也無法紀錄，必需由前端處理)
 *  - receiver: 選取收貨資訊
 *  - store: 選取門市
 */

const PageRoute = () => {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <StyleSheetManager
        enableVendorPrefixes
        shouldForwardProp={(propName, elementToBeRendered) => {
          return typeof elementToBeRendered === 'string'
            ? isPropValid(propName)
            : true;
        }}
      >
        <Suspense>
          <RouterProvider router={router} />
        </Suspense>
      </StyleSheetManager>
    </Provider>
  );
};

export default PageRoute;
