import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  feeRuleByPrice,
  feeRuleInternationalThreshold,
  feeRuleFilterFamilyMart,
  feeRuleCartProgressBar,
  feeRuleDomestic,
  feeRuleFilterDomesticById,
} from 'utils/feeRule';
import { feeRuleByPlatformSelector } from 'reduxs/marketSlice';

// [國際運費] 門檻值(含活動期間判斷)
export const useFeeRuleInternationalThresholdByFee = (platform) => {
  const international = useSelector(
    (state) => feeRuleByPlatformSelector(state, platform).international
  );
  const [data, setData] = useState({});

  useEffect(() => {
    if (!international.length) return;

    setData(feeRuleInternationalThreshold(international[0]));
  }, [international]);

  return data;
};

// [國際運費] 用金額過濾費用與門檻
export const useFeeRuleInternationalThresholdByPrice = (
  platform,
  price = 0
) => {
  const international = useSelector(
    (state) => feeRuleByPlatformSelector(state, platform).international
  );
  const [data, setData] = useState({});

  useEffect(() => {
    if (!international.length) return;

    setData(feeRuleByPrice(international[0], price));
  }, [international, price]);

  return data;
};

// [國內運費] 全家取貨業務邏輯整理(含活動期間判斷)
export const useFeeRuleFamilyMart = (platform) => {
  const domestic = useSelector(
    (state) => feeRuleByPlatformSelector(state, platform).domestic
  );
  const [data, setData] = useState({});

  useEffect(() => {
    if (!domestic.length) return;

    setData(feeRuleFilterFamilyMart(domestic));
  }, [domestic]);

  return data;
};

// [國際運費] 購物車進度條(含活動期間判斷)
export const useCartProgressBar = (platform, totalData) => {
  const international = useSelector(
    (state) => feeRuleByPlatformSelector(state, platform).international
  );
  const [data, setData] = useState({});

  useEffect(() => {
    if (!international.length) return;

    setData(feeRuleCartProgressBar(international[0], totalData));
  }, [international, totalData]);

  return data;
};

// [國內運費] 金額加總過濾費用(含活動期間判斷)
export const useFeeRuleDomestic = (platform, price = 0, id) => {
  const domestic = useSelector(
    (state) => feeRuleByPlatformSelector(state, platform).domestic
  );
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!domestic.length) return;

    const result = feeRuleDomestic(domestic, price);

    setData({
      result,
      current: feeRuleFilterDomesticById(result, id),
    });
  }, [domestic, price, id]);

  return data;
};
