/**
 * 規格表: 過濾該層是否存在對應規格的選項
 * @param {object} params
 * @param {array} params.itemIds - 選項的資料包
 * @param {array} params.itemData - 規格的資料包
 * @param {array} params.selectedIds - 當前選取規格
 * @param {number} params.id - 目標選項的id
 * @param {number} params.variationIdx - 目標選項的層級
 * @returns {boolean}
 */
export const filterVariationOption = ({
  itemIds,
  itemData,
  selectedIds,
  id,
  variationIdx,
}) => {
  if (!selectedIds.length) {
    return false;
  }

  const targetSet = [...selectedIds];
  targetSet.splice(variationIdx, 1, id);
  const matchOption = itemIds.find(
    (obj) => obj.join(',') === targetSet.join(',')
  );

  if (!matchOption) {
    return true;
  }
  const targetItem = itemData.find(
    (item) => item.optionIds.join(',') === matchOption.join(',')
  );

  if (
    (targetItem && targetItem?.maxOrderQuantity === 0) ||
    (targetItem && targetItem?.status === false)
  ) {
    return true;
  }

  return false;
};
