import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice } from '@reduxjs/toolkit';

import { Service, suspense } from 'utils';

const init = {
  title: '',
  products: [],
  totalCount: 0,
};

export const promotionApi = createApi({
  reducerPath: 'promotion/query',
  endpoints: (builder) => ({
    promotion: builder.query({
      queryFn: async (reqData) => {
        let resData = {};
        let retry = 0;
        do {
          resData = await Service.promotion(reqData);

          if (resData.code === -5001) {
            await suspense(500);
            retry += 1;

            // 平均 0.5 秒重打，超過 5 秒就停掉(約 10 次)
            if (retry >= 10) break;
          }
        } while (resData.code === -5001);
        const { code, data, errMsg } = resData;

        // 其他無法預期的錯誤
        if (code < 0 || resData?.hasError) {
          return {
            data: init,
            error: errMsg || '沒有相關商品',
          };
        }

        const formatData = {
          title: data?.title ?? '',
          products: data?.data ?? [],
          totalCount: data?.totalCount ?? 0,
        };
        return { data: formatData };
      },
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        currentCache.products.push(...newItems.products);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { usePromotionQuery, useLazyPromotionQuery } = promotionApi;
export const resetPromotion = () => promotionApi.util.resetApiState();

const initialState = {
  scrollHistory: {},
  init: {
    position: 0,
    visit: 0,
  },
};
const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    resetPromotionScroll: () => initialState,
    setPromotionScroll: (state, { payload }) => {
      state.scrollHistory[payload.key] = {
        position: payload.value,
        visit: payload.time,
      };
    },
  },
});

export const { resetPromotionScroll, setPromotionScroll } =
  promotionSlice.actions;
export default promotionSlice.reducer;
