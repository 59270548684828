import { constants, dayjs } from 'utils';
import { apm } from '@elastic/apm-rum';
const { apiHost, token } = constants;

/**
 * 處理 API 的入口
 * @param {object} params - 需要的參數
 * @returns {promise}
 */
export const serviceProxy = async (params) => {
  const {
    url,
    method = 'get',
    reqHeaders,
    reqData,
    platform = 's3',
    except = false,
    noCache = false,
    isMultipart = false,
  } = params;

  const baseUrl = except ? url : `https://${apiHost[platform]}${url}`;
  const headers = {
    'content-type': 'application/json',
    memberHash: token,
  };

  // 設定檔
  const config = {
    method,
    headers: {
      ...(reqHeaders && { ...reqHeaders }),
      ...(platform === 's3' && {
        'cache-control': noCache
          ? 'no-cache'
          : 'max-age=60, stale-while-revalidate=600',
      }),
    },
    ...(reqData && {
      body: isMultipart ? reqData : JSON.stringify(reqData),
    }),
  };

  // 錯誤模板
  const resConfig = {
    method: method.toUpperCase(),
    apiUrl: url,
  };

  // 會員系列
  if (platform === 'member') {
    config.headers = { ...headers };
  }

  // 購物車與訂單系列
  if (platform === 'cart' || platform === 'order') {
    config.headers = {
      ...headers,
      platformKey: 'family-mart',
    };
  }

  // 僅開發環境顯示 error
  const showErrorMessage = (message) => {
    console.error(message ?? '出了些狀況');
  };

  const handleApmErrorMessage = (httpCode, responseBody, errMsg) => {
    let apiErrorInfo = {
      request: {
        method: method.toUpperCase(),
        url: baseUrl,
        query: baseUrl.split('?')[1] ?? '',
        body: reqData,
      },
    };
    if (httpCode) {
      apiErrorInfo.response = {
        code: httpCode,
        body: responseBody,
      };
    }
    if (errMsg) {
      apiErrorInfo.errorMessage = errMsg;
    }

    apm.captureError(new Error(JSON.stringify(apiErrorInfo)));
  };

  try {
    const response = await fetch(baseUrl, config);

    // 多媒體
    if (isMultipart) {
      return await response.text();
    }

    const result = await response.json();
    const { code, errMsg, message } = result;

    // debug 失敗情境
    if (process.env.REACT_APP_ENV === 'dev' && +code < 1) {
      const error = platform === 'bbn' ? message : errMsg;
      showErrorMessage(
        `${method.toUpperCase()} ${url}\nmessage: ${error ?? '出了些狀況'}`
      );
    }

    // 發 error log 用
    if (+code < 1) {
      handleApmErrorMessage(response.status, result);
      return {
        ...resConfig,
        ...result,
      };
    }

    return result;
  } catch (error) {
    const errorMsg = `${method.toUpperCase()} ${url}\nmessage: ${
      error ?? '出了些狀況'
    }`;
    handleApmErrorMessage(null, null, error);
    showErrorMessage(errorMsg);

    // 發 error log 用
    return {
      ...resConfig,
      hasError: true,
    };
  }
};

/**
 * API 回傳 code 小於 0 的錯誤情境，有些需刻意延遲再重打 fetch
 * @param {number} timer - 秒數
 * @returns {promise}
 */
export const suspense = (timer = 300) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timer);
  });
};

/**
 * 因錯誤需要攔截的資訊
 * @param {string} title
 * @param {string} message
 */
export const sendSlackMessage = async ({
  title = '',
  message = '',
  reqData = '',
  resData = '',
}) => {
  const template = reqData
    ? `*Error Title*\n${title}\n\n*Response Message*\n${message}\n\n*Request Data*\n${reqData}`
    : `*Error Page*\n${title}\n\n*Error Message*\n${message}${
        resData ? `\n\n*Response Data*\n${resData}` : ''
      }`;

  const config = {
    method: 'post',
    body: JSON.stringify({
      channel: '#fmmart',
      username: 'Debug',
      icon_emoji: ':face_with_symbols_on_mouth:',
      attachments: [
        {
          color: '#f2c744',
          blocks: [
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: template,
              },
            },
            {
              type: 'context',
              elements: [
                {
                  type: 'mrkdwn',
                  text: `*@* ${dayjs().format('YYYY/MM/DD HH:mm:ss')}`,
                },
              ],
            },
          ],
        },
      ],
    }),
  };

  try {
    await fetch(
      'https://hooks.slack.com/services/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',
      config
    );
  } catch (error) {
    console.error(`slack error: ${error}`);
  }
};
