import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serviceProxy, Service, formatCurrencyReverseRule } from 'utils';
import { constants } from 'utils';
const { infiniteScrollConfig } = constants;

// 搜尋結果 Banner
export const fetchSearchResultBanner = createAsyncThunk(
  'search/fetchSearchResultBanner',
  async () => {
    const res = await Service.searchResultBanner();
    const { positions } = res.search;

    const contents = await serviceProxy({
      url: positions,
      except: true,
      cache: true,
    });

    const searchResultBanner = await serviceProxy({
      url: contents?.searchResultAdvertisements.rakuma[0],
      except: true,
      cache: true,
    });

    return searchResultBanner;
  }
);

// 處理 query parameters 字串
const handleFilterParams = (params) => {
  let paramsList = [];
  const excludeKeys = ['brandName', 'sellerName', 'platform', 'isSort', 'from'];

  Object.entries(params).map(([key, value]) => {
    if (value && !excludeKeys.includes(key)) {
      if (key === 'minPrice' || key === 'maxPrice') {
        const formatPrice = formatCurrencyReverseRule(key, value);
        paramsList.push(`${key}=${formatPrice}`);
      } else {
        paramsList.push(`${key}=${value}`);
      }
    }
  });

  return paramsList.join('&');
};

// 搜尋結果
export const fetchFilterSearch = createAsyncThunk(
  'search/fetchFilterSearch',
  async (params, { getState, dispatch }) => {
    const state = getState();
    const paramsPayload = handleFilterParams(params);
    const sellerKeyQuery = `&sellerKey=${state.searchFilter.searchResult.sellerKey}`;
    const formatPayload = params.isSort
      ? paramsPayload + sellerKeyQuery
      : paramsPayload;

    dispatch(resetSearchResult());
    const res = await Service.searchResults(formatPayload);

    // 其他非預期錯誤、沒有 data
    const apiResError = res.code < 0 || !res.data;

    const formatRes = apiResError ? initialState.searchResult : res.data;

    if (params.from === 'searchResult') {
      localStorage.setItem(
        infiniteScrollConfig.KEY_PAGE_DATA,
        JSON.stringify(formatRes)
      );
    }

    return formatRes;
  }
);

// 搜尋結果 Load more
export const fetchFilterSearchMore = createAsyncThunk(
  'search/fetchFilterSearch',
  async (params, { getState }) => {
    const state = getState();
    const paramsPayload = handleFilterParams(params);
    const sellerKeyQuery = `&sellerKey=${state.searchFilter.searchResult.sellerKey}`;

    const res = await Service.searchResults(paramsPayload + sellerKeyQuery);
    const formatRes = {
      ...res.data,
      list: [...state.searchFilter.searchResult.list, ...res.data.list],
    };

    localStorage.setItem(
      infiniteScrollConfig.KEY_PAGE_DATA,
      JSON.stringify(formatRes)
    );

    return formatRes;
  }
);

const initialState = {
  advancedSearchDrawerOpen: false,
  dropDownFilterType: null,
  filterText: {
    category: { tempText: '', showText: '' },
    brand: { tempText: '', showText: '' },
    status: { tempText: '', showText: '' },
    price: { tempText: '', showText: '' },
  },
  secondMenu: [], // 分類第二層
  thirdMenu: [], // 分類第三層
  searchResult: { hit: {}, list: [], brandData: {}, sellerData: {} },
  searchResultBanner: [],
  searchLoading: false,
};

const searchFilterSlice = createSlice({
  name: 'searchFilter',
  initialState,
  reducers: {
    setAdvancedSearchDrawerOpen: (state) => {
      state.advancedSearchDrawerOpen = !state.advancedSearchDrawerOpen;
    },
    setDropdownFilterType: (state, { payload }) => {
      state.dropDownFilterType = payload;
    },
    setSearchResult: (state, { payload }) => {
      state.searchResult = payload;
    },
    resetSearchResult: (state) => {
      state.searchResult = initialState.searchResult;
    },
    resetFilterText: (state, { payload }) => {
      if (!payload) {
        state.filterText.category.tempText = '';
        state.filterText.brand.tempText = '';
        state.filterText.status.tempText = '';
        state.filterText.price.tempText = '';
      } else {
        state.filterText[payload].tempText = '';
      }
    },
    setFilterTextTemp: (state, { payload }) => {
      state.filterText[payload.type].tempText = payload.text;
    },
    setFilterTextShow: (state, { payload }) => {
      if (!payload) {
        state.filterText.category.showText = state.filterText.category.tempText;
        state.filterText.brand.showText = state.filterText.brand.tempText;
        state.filterText.status.showText = state.filterText.status.tempText;
        state.filterText.price.showText = state.filterText.price.tempText;
      } else {
        state.filterText[payload].showText = state.filterText[payload].tempText;
      }
    },
    setSecondMenu: (state, { payload }) => {
      state.secondMenu = payload;
    },
    setThirdMenu: (state, { payload }) => {
      state.thirdMenu = payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchResultBanner.fulfilled, (state, { payload }) => {
        state.searchResultBanner = payload;
      })
      .addCase(fetchFilterSearch.pending, (state) => {
        state.searchLoading = true;
      })
      .addCase(fetchFilterSearch.fulfilled, (state, { payload }) => {
        state.searchLoading = false;
        state.searchResult = payload;
      })
      .addCase(fetchFilterSearch.rejected, (state) => {
        state.searchLoading = false;
        state.searchResult = initialState.searchResult;
      });
  },
});

export const {
  setAdvancedSearchDrawerOpen,
  setDropdownFilterType,
  setSearchResult,
  resetSearchResult,
  resetFilterText,
  setFilterTextTemp,
  setFilterTextShow,
  setSecondMenu,
  setThirdMenu,
} = searchFilterSlice.actions;
export default searchFilterSlice.reducer;
