import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Service } from 'utils';

// 整理欄位名稱對應
export const mappingParams = (params) => {
  const {
    coupon_id: id,
    name,
    title,
    description,
    type_icon: icon, // 館別分類-對應商城供應商的icon: (藥妝、二手精品、藥妝-指定onward、指定品牌、指定店鋪、指定分類)
    type_use: status, // 券狀態(可使用、即將上線、不可使用)
    type_group: groupName, // 群組名稱(單品、頻道、館別、其他)
    type_tags: channel, // 頻道
    images, // 僅單品才有
    ticket_qty: availableTimes, // 使用次數，此欄位為倒扣法，直接顯示即可
    start_at: startAt,
    expired_at: endAt,
    order_list: orderList, // 有訂單編號代表已使用，其餘都已過期
    currency,
  } = params;


  return {
    id,
    name: name.replace(';折價券', ''), // 與主站共用結構，value 會寫死 ";折價券"，因此需先濾掉
    title,
    description,
    status,
    icon: icon ?? '',
    groupName,
    channel,
    image: images.length ? images[0] : '',
    availableTimes,
    startAt,
    endAt,
    orderList,
    currency,
  };
};

// 重組資料結構(畫面需要): 列表(狀態、群組)
const formatDataList = (data) =>
  data.reduce(
    (acc, curr) => {
      const { type_use: status, type_group: groupName } = curr;

      acc[status] = acc[status] ?? {};

      // 全部
      acc[status].all = acc[status].all ?? [];
      acc[status].all.push(mappingParams(curr));

      // 頻道與館別需整併(畫面需要)
      if (groupName === 'channel' || groupName === 'category') {
        acc[status].merge = acc[status].merge ?? [];
        acc[status].merge.push(mappingParams(curr));
      } else {
        acc[status][groupName] = acc[status][groupName] ?? [];
        acc[status][groupName].push(mappingParams(curr));
      }

      return acc;
    },
    {
      unuse: {
        all: [], // 需給子層預設值，順序才不會錯亂，群組區塊需按照順序排
        product: [],
        merge: [],
        other: [],
      },
      comingsoon: {
        all: [],
        product: [],
        merge: [],
        other: [],
      },
      used: {
        all: [],
        product: [],
        merge: [],
        other: [],
      },
    }
  );

// 折扣碼兌換折價券
export const codeConvertCoupon = createAsyncThunk(
  'coupon/codeConvertCoupon',
  async (reqData, { getState, rejectWithValue }) => {
    const { userInfo } = getState();
    const { uid } = userInfo;

    const resData = await Service.codeConvertCoupon({
      uid,
      code: reqData,
    });

    // 其他無法預期的錯誤 (API掛掉等等)
    if (resData.hasError) {
      return rejectWithValue({
        type: 'codeConvertCoupon',
        message: '請稍後再試',
      });
    }

    return resData;
  }
);

// 折價券
export const fetchMyCoupon = createAsyncThunk(
  'coupon/fetchMyCoupon',
  async (_, { getState, rejectWithValue }) => {
    const { userInfo } = getState();
    const { uid } = userInfo;
    const resData = await Service.couponList(uid);

    // 其他無法預期的錯誤
    if (resData.hasError) {
      return rejectWithValue({
        type: 'couponList',
        message: 'error!!!',
      });
    }

    // 沒資料會直接吐 status: false，需給空陣列
    const { status, ticket } = resData;
    return status ? ticket : [];
  }
);

// 詳細資訊
export const fetchCouponDetail = createAsyncThunk(
  'coupon/fetchCouponDetail',
  async (reqData, { rejectWithValue, dispatch }) => {
    const resData = await dispatch(fetchMyCoupon()).unwrap();
    const tempData = resData.filter(({ coupon_id }) => coupon_id === reqData);

    // 其他無法預期的錯誤
    if (resData.hasError) {
      return rejectWithValue({
        type: 'detail',
        message: 'error!!!',
      });
    }

    return tempData[0];
  }
);

const initialState = {
  isLoading: {
    list: true,
    detail: true,
  },
  list: {},
  detail: {},
  isErrors: {
    convert: null,
    list: null,
    detail: null,
  },
};

const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    resetData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(codeConvertCoupon.rejected, (state, { payload }) => {
        state.isLoading.detail = false;
        state.isErrors.convert = payload;
      })
      .addCase(fetchMyCoupon.fulfilled, (state, { payload }) => {
        state.isLoading.list = false;
        state.list = formatDataList(payload);
      })
      .addCase(fetchMyCoupon.rejected, (state, { payload }) => {
        state.isLoading.list = false;
        state.isErrors.list = payload;
      })
      .addCase(fetchCouponDetail.fulfilled, (state, { payload }) => {
        state.isLoading.detail = false;
        state.detail = mappingParams(payload);
      })
      .addCase(fetchCouponDetail.rejected, (state, { payload }) => {
        state.isLoading.detail = false;
        state.isErrors.detail = payload;
      });
  },
});

export const { resetData } = couponSlice.actions;
export default couponSlice.reducer;
