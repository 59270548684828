import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  Service,
  suspense,
  constants,
  decodeStorageData,
  setCookieWithDomain,
  setCookie,
  getCookie,
} from 'utils';

const { pageTitle, uid } = constants;
const initialState = {
  city: {},
  adChannelKey: 'drug',
  menuChannelKey: 'drug',
  cartCount: 0,
  pageTitle,
  searchKeyword: '',
  userOrderIdList: [],
  fakeInputVisible: false,
};

// 地址資訊(國家、城市、區)
export const fetchCityList = createAsyncThunk(
  'common/fetchCityList',
  async (reqData) => {
    const resData = await Service.cityList(reqData);
    return resData[0].cities;
  }
);

// 取得購物車數量
export const fetchCartCount = createAsyncThunk(
  'common/fetchCartCount',
  async (_, { rejectWithValue }) => {
    let resData = {};
    let retry = 0;

    do {
      resData = await Service.cartCount();

      if (resData.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData.code === -5001);

    // 其他無法預期的錯誤
    if (resData?.hasError) {
      return rejectWithValue('count API 錯誤');
    }
    if (resData?.code < 0) {
      return rejectWithValue(resData.errMsg);
    }

    return resData.data;
  }
);

// 加入購物車
export const fetchAddToCart = createAsyncThunk(
  'common/fetchAddToCart',
  async (reqData, { rejectWithValue }) => {
    let resData = {};
    let retry = 0;
    const { platform, data } = reqData;
    const cartItems = [
      {
        productId: `${data.productId}`,
        productItemId: `${data.itemId}`,
        productName: data.productName,
        productUrl: `https://${
          process.env.REACT_APP_ENV === 'production'
            ? ''
            : `${process.env.REACT_APP_ENV}-`
        }fmmart.bibian.co.jp/product/${data.productId}?platform=${platform}`,
        productImageUrl: data.productImageUrl,
        listPrice: data.originalPrice,
        salePrice: data.salePrice,
        brandId: data.brandId,
        sellerId: data.sellerId ?? '',
        leadTime: data.leadTime,
        quantity: data.quantity,
        maxOrderQuantity: data.maxOrderQuantity,
        productWeight: data.weight,
        productLength: data?.productLength || 0,
        productWidth: data?.productWidth || 0,
        productHeight: data?.productHeight || 0,
      },
    ];

    do {
      resData = await Service.cartAddItem({
        channelKey: platform,
        cartItems,
      });

      if (resData.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData.code === -5001);

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue(resData.errMsg);
    }

    return resData.code;
  }
);

export const fetchUserOrderIdList = createAsyncThunk(
  'common/fetchUserOrderIdList',
  async (_, { rejectWithValue }) => {
    try {
      const resData = await Service.orderSearch({
        ez1: constants.token,
        status: 'getorderno',
        platform: 'familyMart',
      });
      return resData.orderNo;
    } catch (err) {
      return rejectWithValue(err.toString());
    }
  }
);

// FIXME: 搬移到 userInfo 後再清除，舊的先保留
// 取得 userId 並寫入 cookie
export const fetchUserId = createAsyncThunk(
  'common/fetchUserId',
  async (_, { rejectWithValue }) => {
    let resData = 0;
    let retry = 0;

    // 有 cookie 就不重打
    if (uid) return;

    do {
      resData = await Service.decodeToken();
      if (resData?.hasError) {
        break;
      }

      if (resData?.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData?.code === -5001);

    // 其他無法預期的錯誤
    if (resData?.code && resData?.code < 0) {
      return rejectWithValue(resData.errMsg);
    }

    // 將 userId 寫入 cookie
    setCookieWithDomain('uid', resData.data.id);
    return resData;
  }
);

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setMenuChannelKey: (state, { payload }) => {
      state.menuChannelKey = payload;
    },
    setPageTitle: (state, { payload }) => {
      state.pageTitle = payload;
    },
    setSearchKeyword: (state, { payload }) => {
      state.searchKeyword = payload;
    },
    setAdChannelKey: (state, { payload }) => {
      state.adChannelKey = payload;
    },
    setFakeInputVisible: (state, { payload }) => {
      state.fakeInputVisible = payload;
    },
    resetPageTitle: (state) => {
      state.pageTitle = initialState.pageTitle;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCityList.fulfilled, (state, { payload }) => {
        state.city = payload.reduce(
          (acc, { cityCode, cityName, districts }) => {
            acc[cityCode] = {
              code: cityCode,
              name: cityName,
              areas: districts,
            };
            return acc;
          },
          {}
        );
      })
      .addCase(fetchCartCount.fulfilled, (state, { payload }) => {
        state.cartCount = payload.counts;
      })
      .addCase(fetchCartCount.rejected, (state, { payload }) => {
        state.cartCount = initialState.cartCount;
        state.message = payload;
      })
      .addCase(fetchUserOrderIdList.fulfilled, (state, { payload }) => {
        state.userOrderIdList = payload;
      })
      .addCase(fetchUserOrderIdList.rejected, (state) => {
        state.userOrderIdList = initialState.userOrderIdList;
      })
      .addCase(fetchUserId.rejected, (state, { payload }) => {
        state.message = payload;
      });
  },
});

export const {
  setMenuChannelKey,
  setPageTitle,
  setSearchKeyword,
  setAdChannelKey,
  setFakeInputVisible,
  resetPageTitle,
} = commonSlice.actions;
export default commonSlice.reducer;

// 已選取的收貨資訊與全家門市，僅選取完暫存，一旦資料送出要清掉
export const storageDataReceiverAndStore = () => {
  const tempData = {
    receiver: decodeStorageData('selectedReceiver') ?? {},
    store: decodeStorageData('selectedStore') ?? {},
  };
  return tempData;
};

// 匯率 v2.0
export const exchangeApi = createApi({
  reducerPath: 'exchangeApi',
  endpoints: (builder) => ({
    exchange: builder.query({
      queryFn: async () => {
        // 避免網路斷線錯誤, 增加重試次數
        let retry = 0;
        let resData = null;
        do {
          resData = await await Service.exchange(process.env.REACT_APP_ENV);
          if (resData?.hasError) {
            await suspense(1000);
            retry += 1;
            // 平均 1 秒重打，超過 5 秒就停掉(約 5 次)
            if (retry >= 5) break;
          }
        } while (resData?.hasError);

        // 重試後, 依然出現無法預期的錯誤(ex. 找不到檔案而造成的 error)
        if (resData.hasError) {
          const cookieData = getCookie('exchange');

          if (cookieData) {
            return JSON.parse(cookieData);
          }

          return { error: '系統 error' };
        }

        setCookie('exchange', JSON.stringify(+resData[0].value_var));
        return { data: +resData[0].value_var };
      },
    }),
  }),
});

export const { useExchangeQuery } = exchangeApi;
