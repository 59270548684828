import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Routes';
import Cookies from 'js-cookie';
import { init as initApm } from '@elastic/apm-rum';

const isLocal = process.env.NODE_ENV === 'development';

if (!isLocal) {
  const userToken = Cookies.get('ez1') ?? '';
  const apm = initApm({
    serviceName: 'familymart',
    serverUrl: 'https://bbn-elk.apm.ap-northeast-1.aws.found.io',
    environment: process.env.REACT_APP_ENV,
    // logLevel: 'debug',
    distributedTracingOrigins: [
      `https://${process.env.REACT_APP_HOST_S3}`,
      // `https://${process.env.REACT_APP_HOST_BBN}`, // not allow apm trace header
      // `https://${process.env.REACT_APP_HOST_DRUG}`, // not allow apm trace header
      // `https://${process.env.REACT_APP_HOST_AMAZON}`, // currently not use
      // `https://${process.env.REACT_APP_HOST_MEMBER}`,
      // `https://${process.env.REACT_APP_HOST_CART}`, // not allow apm trace header
      `https://${process.env.REACT_APP_HOST_ORDER}`,
      `https://${process.env.REACT_APP_HOST_RAKUMA}`,
    ],
    ignoreTransactions: [
      new RegExp(/trc-events.taboola.com/),
      new RegExp(/analytics.google.com/),
    ],
    transactionSampleRate: 0.3,
  });

  const apmUuid = localStorage.getItem('apmUuid');
  apm.addLabels({ ['user-uuid']: apmUuid });
  apm.setUserContext({ id: userToken });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// StrictMode 先拔掉，不然 fetch 會兩次
// test comment
